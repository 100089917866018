import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { startOfWeek, addDays, format, subWeeks, addWeeks } from 'date-fns';
import { fr } from 'date-fns/locale';

const AdminPanel = styled.div`
  margin: 20px auto;
  font-family: Arial, sans-serif;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  display: block;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Select = styled.select`
  display: block;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Button = styled.button`
  background-color: #007BFF;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;

const SwitchModeButton = styled(Button)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;

const WeekSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: #0056b3;
    }
  }

  span {
    font-size: 16px;
    font-weight: bold;
  }
`;

function AdminView() {
  const timeSlots = [
    '21:00', '21:20', '21:40', '22:00', '22:20', '22:40', '23:00', '23:20',
  ];

  const [entry, setEntry] = useState({
    day: 'Lundi',
    startTime: timeSlots[0],
    endTime: '00:00', // Always default to 00:00 for end time
    type: '',
    competitionName: '',
    team: '',
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(new Date());

  const handlePrevWeek = () => setSelectedWeek(subWeeks(selectedWeek, 1));
  const handleNextWeek = () => setSelectedWeek(addWeeks(selectedWeek, 1));

  const handleSubmit = async () => {
    const startOfSelectedWeek = startOfWeek(selectedWeek, { weekStartsOn: 1 });
    const dayIndex = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi'].indexOf(entry.day);
    const date = format(addDays(startOfSelectedWeek, dayIndex), 'yyyy-MM-dd', { locale: fr });

    const payload = {
      date,
      startTime: entry.startTime,
      endTime: entry.endTime,
      type: entry.type,
      competitionName: entry.competitionName,
      team: entry.team,
    };

    try {
      if (isEditMode) {
        // Modify an existing schedule by date and startTime
        await axios.put('http://www.academiagames.fr:5000/schedule/edit', payload);
        alert('Créneau modifié avec succès !');
      } else {
        // Create a new schedule
        await axios.post('http://www.academiagames.fr:5000/schedule', payload);
        alert('Créneau ajouté avec succès !');
      }
      setEntry({ day: 'Lundi', startTime: timeSlots[0], endTime: '00:00', type: '', competitionName: '', team: '' });
    } catch (error) {
      console.error('Erreur lors de la soumission du créneau :', error);
    }
  };

  const toggleMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <AdminPanel>
      <h2>Panneau Administrateur</h2>
      <SwitchModeButton onClick={toggleMode}>
        {isEditMode ? 'Passer en mode création' : 'Passer en mode modification'}
      </SwitchModeButton>
      <WeekSelector>
        <button onClick={handlePrevWeek}>Semaine précédente</button>
        <span>{`Semaine du ${format(startOfWeek(selectedWeek, { weekStartsOn: 1 }), 'dd/MM/yyyy', { locale: fr })}`}</span>
        <button onClick={handleNextWeek}>Semaine suivante</button>
      </WeekSelector>
      <Input
        type="text"
        placeholder="Nom de la compétition"
        value={entry.competitionName}
        onChange={(e) => setEntry({ ...entry, competitionName: e.target.value })}
      />
      <Input
        type="text"
        placeholder="Nom de l'équipe"
        value={entry.team}
        onChange={(e) => setEntry({ ...entry, team: e.target.value })}
      />
      <Select
        value={entry.day}
        onChange={(e) => setEntry({ ...entry, day: e.target.value })}
      >
        {['Lundi', 'Mardi', 'Mercredi', 'Jeudi'].map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </Select>
      <Select
        value={entry.startTime}
        onChange={(e) => setEntry({ ...entry, startTime: e.target.value })}
      >
        {timeSlots.map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </Select>
      <Button onClick={handleSubmit}>{isEditMode ? 'Modifier' : 'Ajouter'}</Button>
    </AdminPanel>
  );
}

export default AdminView;

