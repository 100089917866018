import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  font-family: Arial, sans-serif;
`;

const Field = styled.div`
  position: relative;
  width: 600px;
  height: 800px;
  background: #006400;
  border: 5px solid white;
  border-radius: 10px;
  overflow: hidden;
`;

const Line = styled.div`
  position: absolute;
  background-color: white;
`;

const CenterLine = styled(Line)`
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
`;

const GoalArea = styled.div`
  position: absolute;
  width: 150px;
  height: 50px;
  border: 3px solid white;
  top: ${(props) => (props.position === "top" ? "5px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "5px" : "auto")};
  left: 50%;
  transform: translateX(-50%);
`;

const PenaltyArea = styled.div`
  position: absolute;
  width: 300px;
  height: 100px;
  border: 3px solid white;
  top: ${(props) => (props.position === "top" ? "5px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "5px" : "auto")};
  left: 50%;
  transform: translateX(-50%);
`;

const PenaltySpot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  top: ${(props) => (props.position === "top" ? "80px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "80px" : "auto")};
  left: 50%;
  transform: translateX(-50%);
`;

const Arc = styled.div`
  position: absolute;
  width: 120px;
  height: 30px;
  border: 3px solid white;
  border-top: none;
  border-radius: 0 0 120px 120px;
  top: ${(props) => (props.position === "top" ? "110px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "110px" : "auto")};
  left: 50%;
  transform: translateX(-50%) rotate(${(props) => (props.position === "top" ? "0deg" : "180deg")});
`;

const CenterCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  border: 3px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

const CenterSpot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

const CornerArc = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid white;
  border-radius: 50%;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  top: ${(props) =>
    props.position === "top-left" || props.position === "top-right" ? "0" : "auto"};
  bottom: ${(props) =>
    props.position === "bottom-left" || props.position === "bottom-right" ? "0" : "auto"};
  left: ${(props) =>
    props.position === "top-left" || props.position === "bottom-left" ? "0" : "auto"};
  right: ${(props) =>
    props.position === "top-right" || props.position === "bottom-right" ? "0" : "auto"};
  transform: ${(props) =>
    props.position === "top-left"
      ? "translate(0, 0) rotate(90deg)"
      : props.position === "top-right"
      ? "translate(0, 0) rotate(-180deg)"
      : props.position === "bottom-left"
      ? "translate(0, 0) rotate(0deg)"
      : "translate(0, 0) rotate(-90deg)"};
`;

const PlayerContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
`;

const Player = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: black;
  cursor: grab;
  user-select: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
`;

const Select = styled.select`
  position: absolute;
  width: 120px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  border: 2px solid #007bff;
  border-radius: 5px;
  outline: none;

  &:focus {
    border-color: #0056b3;
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }
`;

function TeamComposition() {
  const [players, setPlayers] = useState([]);
  const [effectif, setEffectif] = useState([]);
  const [editingPlayer, setEditingPlayer] = useState(null);

  useEffect(() => {
    fetchComposition();
    fetchEffectif();
  }, []);

  const fetchComposition = async () => {
    try {
      const response = await axios.get("http://www.academiagames.fr:5000/players");
      setPlayers(response.data);
    } catch (error) {
      console.error("Error fetching composition:", error);
    }
  };

  const fetchEffectif = async () => {
    try {
      const response = await axios.get("http://www.academiagames.fr:5000/effectif");
      setEffectif([{ id: "bot", pseudo: "BOT" }, ...response.data]); // Ajouter "BOT" en premier
    } catch (error) {
      console.error("Error fetching effectif:", error);
    }
  };
  
  const updatePlayerPosition = async (id, x, y) => {
    try {
      await axios.put(`http://www.academiagames.fr:5000/players/${id}`, { x, y });
      fetchComposition();
    } catch (error) {
      console.error("Error updating player position:", error);
    }
  };

  const updatePlayerName = async (id, name) => {
    try {
      await axios.put(`http://www.academiagames.fr:5000/players/${id}`, { name });
      fetchComposition();
    } catch (error) {
      console.error("Error updating player name:", error);
    }
  };

  const handleDrag = (e, id) => {
    const field = document.querySelector("#football-field");
    const rect = field.getBoundingClientRect();

    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    if (x >= 0 && x <= 100 && y >= 0 && y <= 100) {
      setPlayers((prev) =>
        prev.map((player) => (player.id === id ? { ...player, x, y } : player))
      );
      updatePlayerPosition(id, x, y);
    }
  };

  const saveNameChange = (id, name) => {
    updatePlayerName(id, name);
    setEditingPlayer(null);
  };

  return (
    <PageContainer>
      <h1>Composition d'équipe</h1>
      <Field id="football-field">
        <CenterLine />
        <CenterCircle />
        <CenterSpot />
        <GoalArea position="top" />
        <GoalArea position="bottom" />
        <PenaltyArea position="top" />
        <PenaltyArea position="bottom" />
        <PenaltySpot position="top" />
        <PenaltySpot position="bottom" />
        <Arc position="top" />
        <Arc position="bottom" />
        <CornerArc position="top-left" />
        <CornerArc position="top-right" />
        <CornerArc position="bottom-left" />
        <CornerArc position="bottom-right" />

        {players.map((player) => (
          <PlayerContainer
            key={player.id}
            style={{ left: `${player.x}%`, top: `${player.y}%` }}
          >
            {editingPlayer === player.id ? (
              <Select
                value={player.name}
                onChange={(e) => saveNameChange(player.id, e.target.value)}
                onBlur={() => setEditingPlayer(null)}
              >
                <option value="">Sélectionner un joueur</option>
                {effectif.map((e) => (
                  <option key={e.id} value={e.pseudo}>
                    {e.pseudo}
                  </option>
                ))}
              </Select>
            ) : (
              <Player
                draggable
                onDragEnd={(e) => handleDrag(e, player.id)}
                onDoubleClick={() => setEditingPlayer(player.id)}
              >
                {player.name}
              </Player>
            )}
          </PlayerContainer>
        ))}
      </Field>
      <Button onClick={fetchComposition}>Réinitialiser la Composition</Button>
    </PageContainer>
  );
}

export default TeamComposition;

