import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  position: relative;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Field = styled.div`
  position: relative;
  width: 600px;
  height: 800px;
  background: #006400;
  border: 5px solid white;
  border-radius: 10px;
  overflow: hidden;
`;

const Line = styled.div`
  position: absolute;
  background-color: white;
`;

const CenterLine = styled(Line)`
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
`;

const GoalArea = styled.div`
  position: absolute;
  width: 150px;
  height: 50px;
  border: 3px solid white;
  top: ${(props) => (props.position === "top" ? "5px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "5px" : "auto")};
  left: 50%;
  transform: translateX(-50%);
`;

const PenaltyArea = styled.div`
  position: absolute;
  width: 300px;
  height: 100px;
  border: 3px solid white;
  top: ${(props) => (props.position === "top" ? "5px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "5px" : "auto")};
  left: 50%;
  transform: translateX(-50%);
`;

const PenaltySpot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  top: ${(props) => (props.position === "top" ? "80px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "80px" : "auto")};
  left: 50%;
  transform: translateX(-50%);
`;

const Arc = styled.div`
  position: absolute;
  width: 120px;
  height: 30px;
  border: 3px solid white;
  border-top: none;
  border-radius: 0 0 120px 120px;
  top: ${(props) => (props.position === "top" ? "110px" : "auto")};
  bottom: ${(props) => (props.position === "bottom" ? "110px" : "auto")};
  left: 50%;
  transform: translateX(-50%) rotate(${(props) => (props.position === "top" ? "0deg" : "180deg")});
`;

const CenterCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  border: 3px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

const CenterSpot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

const CornerArc = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid white;
  border-radius: 50%;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  top: ${(props) =>
    props.position === "top-left" || props.position === "top-right" ? "0" : "auto"};
  bottom: ${(props) =>
    props.position === "bottom-left" || props.position === "bottom-right" ? "0" : "auto"};
  left: ${(props) =>
    props.position === "top-left" || props.position === "bottom-left" ? "0" : "auto"};
  right: ${(props) =>
    props.position === "top-right" || props.position === "bottom-right" ? "0" : "auto"};
  transform: ${(props) =>
    props.position === "top-left"
      ? "translate(0, 0) rotate(90deg)"
      : props.position === "top-right"
      ? "translate(0, 0) rotate(-180deg)"
      : props.position === "bottom-left"
      ? "translate(0, 0) rotate(0deg)"
      : "translate(0, 0) rotate(-90deg)"};
`;

const PlayerContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
`;

const Player = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: black;
  user-select: none;
`;

const TableContainer = styled.div`
  position: absolute;
  right: 1%;
  top: 80px;
  width: 300px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
`;

const TableHead = styled.thead`
  background: #007bff;
  color: white;

  th {
    padding: 10px;
    border: 1px solid white;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #ddd;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

function TeamView() {
  const [players, setPlayers] = useState([]);
  const [effectif, setEffectif] = useState([]);

  useEffect(() => {
    fetchComposition();
    fetchEffectif();
  }, []);

  const fetchComposition = async () => {
    try {
      const response = await axios.get("http://www.academiagames.fr:5000/players");
      setPlayers(response.data);
    } catch (error) {
      console.error("Error fetching composition:", error);
    }
  };

  const fetchEffectif = async () => {
    try {
      const response = await axios.get("http://www.academiagames.fr:5000/effectif");
      setEffectif(response.data);
    } catch (error) {
      console.error("Error fetching effectif:", error);
    }
  };

  const displayedPlayers = players
    .filter((player) => player.name !== "BOT")
    .map((player) => {
      const foundEffectif = effectif.find((e) => e.pseudo === player.name);
      return {
        name: player.name,
        instruction: foundEffectif ? foundEffectif.commentaire : "Instruction",
      };
    });

  return (
    <PageContainer>
      <FieldContainer>
        <h1>Composition d'équipe</h1>
        <Field id="football-field">
          <CenterLine />
          <CenterCircle />
          <CenterSpot />
          <GoalArea position="top" />
          <GoalArea position="bottom" />
          <PenaltyArea position="top" />
          <PenaltyArea position="bottom" />
          <PenaltySpot position="top" />
          <PenaltySpot position="bottom" />
          <Arc position="top" />
          <Arc position="bottom" />
          <CornerArc position="top-left" />
          <CornerArc position="top-right" />
          <CornerArc position="bottom-left" />
          <CornerArc position="bottom-right" />

          {players.map((player) => (
            <PlayerContainer
              key={player.id}
              style={{ left: `${player.x}%`, top: `${player.y}%` }}
            >
              <Player>{player.name}</Player>
            </PlayerContainer>
          ))}
        </Field>
      </FieldContainer>
      <TableContainer>
        <Table>
          <TableHead>
            <tr>
              <th>Joueur</th>
              <th>Instruction</th>
            </tr>
          </TableHead>
          <tbody>
            {displayedPlayers.map((player, index) => (
              <TableRow key={index}>
                <TableCell>{player.name}</TableCell>
                <TableCell>{player.instruction}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
}

export default TeamView;

