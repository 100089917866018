import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { addDays, startOfWeek, format, subWeeks, addWeeks, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

const CalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px repeat(4, 1fr);
  gap: 10px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  height: 100vh;
  margin: 20px auto;
  overflow: hidden;

  @media (max-width: 768px) {
    grid-template-columns: 60px repeat(4, 1fr);
    max-width: 100%;
  }

  @media (max-width: 480px) {
    grid-template-columns: 50px repeat(4, 1fr);
    max-width: 100%;
    gap: 5px;
  }
`;

const TimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-right: 2px solid #ccc;

  @media (max-width: 480px) {
    border-right: 1px solid #ccc;
  }
`;

const TimeSlotHeader = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  flex: 1;
  background-color: #e9ecef;
  color: #495057;

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 3px;
  }
`;

const DayColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-left: 1px solid #ccc;

  @media (max-width: 480px) {
    border-left: 1px solid #ccc;
  }
`;

const DayHeader = styled.div`
  background-color: #007BFF;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  padding: 5px;
  border-bottom: 1px solid #0056b3;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  .date {
    font-size: 10px;
    margin-top: 3px;
    color: #e0e0e0;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 3px;

    .date {
      font-size: 8px;
    }
  }
`;

const TimeSlot = styled.div`
  padding: 5px;
  border-bottom: 1px solid #ccc;
  background-color: ${(props) =>
    props.nonAvailable
      ? '#ffe5e5'
      : props.filled
      ? '#fff8dc'
      : '#ffffff'};
  transition: background-color 0.3s ease;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      props.nonAvailable
        ? '#ffd6d6'
        : props.filled
        ? '#ffefc6'
        : '#f9f9f9'};
  }

  div {
    font-size: 12px;
    text-align: center;
    color: ${(props) => (props.nonAvailable ? '#721c24' : '#212529')};

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }

  .title {
    font-weight: bold;
    margin-bottom: 5px;

    @media (max-width: 480px) {
      margin-bottom: 3px;
      font-size: 10px;
    }
  }
`;

const WeekNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    margin: 0 15px;
  }
`;

const Button = styled.button`
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }
`;

function UserView() {
  const [schedule, setSchedule] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(new Date());

  useEffect(() => {
    fetchSchedule();
  }, [currentWeek]);

  const fetchSchedule = async () => {
    const startOfCurrentWeek = format(
      startOfWeek(currentWeek, { weekStartsOn: 1 }),
      'yyyy-MM-dd'
    );
    const endOfCurrentWeek = format(addDays(startOfCurrentWeek, 3), 'yyyy-MM-dd');

    try {
      const response = await axios.get(
        'http://www.academiagames.fr:5000/schedule/week',
        {
          params: { startDate: startOfCurrentWeek, endDate: endOfCurrentWeek },
        }
      );

      const transformedData = response.data.map((entry) => ({
        ...entry,
        date: parseISO(entry.date),
      }));

      setSchedule(transformedData);
    } catch (error) {
      console.error('Error fetching schedule:', error);
    }
  };

  const timeSlots = [
    '21:00',
    '21:20',
    '21:40',
    '22:00',
    '22:20',
    '22:40',
    '23:00',
    '23:20',
  ];
  const startOfCurrentWeek = startOfWeek(currentWeek, { weekStartsOn: 1 });
  const days = Array.from({ length: 4 }, (_, i) =>
    format(addDays(startOfCurrentWeek, i), 'yyyy-MM-dd')
  );

  const handlePrevWeek = () => setCurrentWeek(subWeeks(currentWeek, 1));
  const handleNextWeek = () => setCurrentWeek(addWeeks(currentWeek, 1));

  return (
    <>
      <WeekNavigation>
        <Button onClick={handlePrevWeek}>Précédent</Button>
        <h3>{`Semaine du ${format(startOfCurrentWeek, 'dd/MM/yyyy', {
          locale: fr,
        })}`}</h3>
        <Button onClick={handleNextWeek}>Suivant</Button>
      </WeekNavigation>
      <CalendarWrapper>
        <TimeColumn>
          <TimeSlotHeader>Horaires</TimeSlotHeader>
          {timeSlots.map((time) => (
            <TimeSlotHeader key={time}>{time}</TimeSlotHeader>
          ))}
        </TimeColumn>
        {days.map((day) => (
          <DayColumn key={day}>
            <DayHeader>
              {format(parseISO(day), 'eeee', { locale: fr })}
              <span className="date">{format(parseISO(day), 'dd/MM/yyyy')}</span>
            </DayHeader>
            {timeSlots.map((time) => {
              const slot = schedule.find(
                (s) =>
                  format(s.date, 'yyyy-MM-dd') === day &&
                  s.startTime === time
              );
              return (
                <TimeSlot
                  key={`${day}-${time}`}
                  filled={!!slot && slot.competitionName !== 'Non disponible'}
                  nonAvailable={slot?.competitionName === 'Non disponible'}
                >
                  {slot && slot.competitionName !== 'Non disponible' && (
                    <>
                      <div className="title">{slot.competitionName}</div>
                      <div>Contre : {slot.team}</div>
                    </>
                  )}
                  {slot?.competitionName === 'Non disponible' && (
                    <div>Non disponible</div>
                  )}
                </TimeSlot>
              );
            })}
          </DayColumn>
        ))}
      </CalendarWrapper>
    </>
  );
}

export default UserView;

