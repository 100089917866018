import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
`;

const TableHead = styled.thead`
  background-color: #007bff;
  color: white;

  th {
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #ddd;
  }
`;

const TableCell = styled.td`
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: ${(props) => props.color || "#007bff"};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.hoverColor || "#0056b3"};
  }
`;

const FormContainer = styled.div`
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
`;

const Input = styled.input`
  margin: 5px 0;
  padding: 10px;
  width: calc(100% - 22px);
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Select = styled.select`
  margin: 5px 0;
  padding: 10px;
  width: calc(100% - 22px);
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Label = styled.label`
  margin: 5px 0;
`;

function EffectifPage() {
  const [players, setPlayers] = useState([]);
  const [form, setForm] = useState({
    pseudo: "",
    poste_principal: "",
    poste_secondaire: "",
    etat: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [sortField, setSortField] = useState("");

  const postes = [
    "Aucun",
    "Gardien",
    "Défenseur Gauche",
    "Défenseur Central",
    "Défenseur Droit",
    "Milieu Défensif Gauche",
    "Milieu Défensif Central",
    "Milieu Défensif Droit",
    "Milieu Gauche",
    "Milieu Central",
    "Milieu Droit",
    "Milieu Offensif Central",
    "Ailier Gauche",
    "Ailier Droit",
    "Attaquant Gauche",
    "Attaquant Droit",
    "Buteur",
  ];

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      const response = await axios.get("http://www.academiagames.fr:5000/effectif");
      setPlayers(response.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`http://www.academiagames.fr:5000/effectif/${editId}`, form);
      } else {
        await axios.post("http://www.academiagames.fr:5000/effectif", form);
      }
      setForm({ pseudo: "", poste_principal: "", poste_secondaire: "", etat: "" });
      setIsEditing(false);
      setEditId(null);
      fetchPlayers();
    } catch (error) {
      console.error("Error saving player:", error);
    }
  };

  const handleEdit = (player) => {
    setForm(player);
    setIsEditing(true);
    setEditId(player.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://www.academiagames.fr:5000/effectif/${id}`);
      fetchPlayers();
    } catch (error) {
      console.error("Error deleting player:", error);
    }
  };

  const handleSort = (field) => {
    const sorted = [...players].sort((a, b) =>
      a[field].localeCompare(b[field], undefined, { sensitivity: "base" })
    );
    setPlayers(sorted);
    setSortField(field);
  };

  return (
    <Container>
      <Title>Gestion de l'effectif</Title>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <Label>Pseudo:</Label>
          <Input
            type="text"
            name="pseudo"
            value={form.pseudo}
            onChange={handleInputChange}
            required
          />
          <Label>Poste Principal:</Label>
          <Select
            name="poste_principal"
            value={form.poste_principal}
            onChange={handleInputChange}
            required
          >
            <option value="">Choisir un poste</option>
            {postes.map((poste) => (
              <option key={poste} value={poste}>
                {poste}
              </option>
            ))}
          </Select>
          <Label>Poste Secondaire:</Label>
          <Select
            name="poste_secondaire"
            value={form.poste_secondaire}
            onChange={handleInputChange}
          >
            <option value="">Choisir un poste</option>
            {postes.map((poste) => (
              <option key={poste} value={poste}>
                {poste}
              </option>
            ))}
          </Select>
          <Label>État:</Label>
          <Select
            name="etat"
            value={form.etat}
            onChange={handleInputChange}
            required
          >
            <option value="">Choisir un état</option>
            <option value="titulaire">Titulaire</option>
            <option value="remplaçant">Remplaçant</option>
            <option value="dépannage">Dépannage</option>
            <option value="test">Test</option>
          </Select>
          <Button type="submit" color="#28a745" hoverColor="#218838">
            {isEditing ? "Modifier" : "Créer"}
          </Button>
        </form>
      </FormContainer>
      <Table>
        <TableHead>
          <tr>
            <th onClick={() => handleSort("pseudo")}>Pseudo</th>
            <th onClick={() => handleSort("poste_principal")}>Poste Principal</th>
            <th onClick={() => handleSort("poste_secondaire")}>Poste Secondaire</th>
            <th onClick={() => handleSort("etat")}>État</th>
            <th>Actions</th>
          </tr>
        </TableHead>
        <tbody>
          {players.map((player) => (
            <TableRow key={player.id}>
              <TableCell>{player.pseudo}</TableCell>
              <TableCell>{player.poste_principal}</TableCell>
              <TableCell>{player.poste_secondaire || "N/A"}</TableCell>
              <TableCell>{player.etat}</TableCell>
              <TableCell>
                <Button
                  color="#ffc107"
                  hoverColor="#e0a800"
                  onClick={() => handleEdit(player)}
                >
                  Modifier
                </Button>
                <Button
                  color="#dc3545"
                  hoverColor="#c82333"
                  onClick={() => handleDelete(player.id)}
                >
                  Supprimer
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default EffectifPage;

