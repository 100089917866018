// frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import UserView from './UserView';
import AdminView from './AdminView';
import TeamComposition from './TeamComposition';
import TeamView from './TeamView';
import EffectifPage from './EffectifPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/overseefc/planning" element={<UserView />} />
        <Route path="/overseefc/admmonitor" element={<AdminView />} />
        <Route path="/overseefc/composition" element={<TeamView />} />
        <Route path="/overseefc/editcomposition" element={<TeamComposition />} />
        <Route path="/overseefc/effectif" element={<EffectifPage />} />
        <Route path="*" element={<Navigate to="/overseefc/planning" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
